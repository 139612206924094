import type { DrawerSettings } from '@skeletonlabs/skeleton';
import type { AllChangelogUpdates } from '@tickrr/db/admin';
import type { DrawerStore } from '@tickrr/ui/types';

import { defaultDrawerSettings } from '@tickrr/ui/components/v2/elements/drawer/defaultSettings.ts';

export type editChangelogDrawerMeta = {
	initialUpdateData: AllChangelogUpdates[number];
	isNew: boolean;
	refetchUpdates: () => Promise<unknown>;
};

export const editChangelogDrawerSettings: DrawerSettings = {
	...defaultDrawerSettings.right,
	id: 'back-office:edit-changelog-drawer'
};

export const openEditChangelogDrawer = (
	drawerStore: DrawerStore,
	meta?: editChangelogDrawerMeta
) => {
	drawerStore.open({ ...editChangelogDrawerSettings, meta });
	return;
};
